import React, { useEffect, useState } from "react";
import "./OneTrustPrivacyPolicy.scss";

const OneTrustPrivacyPolicy = (props) => {
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    
    const script = document.createElement('script');
    script.src = 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.id = 'otprivacy-notice-script';
    script.async = true;

    script.setAttribute('settings', 'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyIsImNvbnRlbnRBcGlVcmwiOiJodHRwczovL3ByaXZhY3lwb3J0YWwub25ldHJ1c3QuY29tL3JlcXVlc3QvdjEvZW50ZXJwcmlzZXBvbGljeS9kaWdpdGFscG9saWN5L2NvbnRlbnQiLCJtZXRhZGF0YUFwaVVybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9lbnRlcnByaXNlcG9saWN5L2RpZ2l0YWxwb2xpY3kvbWV0YS1kYXRhIn0=');

    document.body.appendChild(script);

    script.onload = () => {
      if (window.OneTrust && window.OneTrust.NoticeApi) {
        window.OneTrust.NoticeApi.Initialized.then(() => {
          window.OneTrust.NoticeApi.LoadNotices([
            'https://privacyportal-cdn.onetrust.com/storage-container/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/2a11a97d-d146-409f-90e6-3ba048440ad5/published/privacynotice.json'
          ]);
          const observer = new MutationObserver((mutations, obs) => {
            const container = document.getElementById('otnotice-2a11a97d-d146-409f-90e6-3ba048440ad5');
            if (container && container.childNodes.length > 0) {
              console.log("loaded content")
              setIsLoading(false);
              obs.disconnect(); 
            }
          });

          observer.observe(document.body, {
            childList: true,
            subtree: true
          });
        });
      }
    };

    return () => {
      document.body.appendChild(script); 
    };
  }, []);

  return (
    <section className="one-trust-privacy-policy-page">
      
      <div className="container">
        <div className="loader-container" style={{ display: isLoading ? 'block' : 'none' }}>
          <div className="loading-paragraphs">
            <div className="loading-paragraph"></div>
            <div className="loading-paragraph"></div>
            <div className="loading-paragraph"></div>
            <div className="loading-paragraph"></div>
            <div className="loading-paragraph"></div>
          </div>
        </div> 
      </div>
      
      <div className="container"  style={{ display: !isLoading ? 'block' : 'none' }}>
        <div className="ot-privacy-notice-language-dropdown-container"></div>
        <div id="otnotice-2a11a97d-d146-409f-90e6-3ba048440ad5" className="otnotice"></div>
      </div>
      
    </section>
  );
};

export default OneTrustPrivacyPolicy;
