import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import Layout from "../components/Layout/Layout"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import { Script } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import OneTrustPrivacyPolicy from "../components/OneTrustPrivacyPolicy/OneTrustPrivacyPolicy"

const PrivacyPage = () => {

  const data = useStaticQuery(graphql`
    query PrivacyAndCookiePolicyPageQuery {
      prismicBlindcvPrivacyAndCookiePolicyPage {
        data {
          page_title {
            text
            html
          }
          analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                id
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Layout pageViewAnalyticsData={
        data.prismicBlindcvPrivacyAndCookiePolicyPage.data.analytics.document.data
      }>
        <NavigationBar />
        <div className="page-content">
          <OneTrustPrivacyPolicy />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}

export default PrivacyPage
