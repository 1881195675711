import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import logo2 from "./../../../images/by-digitas.png"

import "./SiteFooter.scss"
// import { DEFAULT_LANG } from "gatsby-source-prismic"

const SiteFooter = () => {
  const siteURL = process.env.GATSBY_APP_DOMAIN
  const location = useLocation()
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${siteURL}${location.pathname}%2F&amp;src=sdkpreparse&quote=Check out Anon CV by Digitas`
  const twitterUrl = `https://twitter.com/intent/tweet?url=${siteURL}${location.pathname}&text=Check out Anon CV by Digitas %7c `
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${siteURL}${location.pathname}`

  const data = useStaticQuery(graphql`
    query FooterQuery {
      prismicBlindcvFooter {
        data {
          copyright_logo {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          copyright_text
          cv_logo {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          digitas_logo {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          share_title {
            text
            html
          }
          social_description
          social_share {
            social_logo {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            social_title
          }
          menu {
            href
            title
          }
        }
      }
    }
  `)

  const FooterMenu = (data) => {
    let navLink = data.data.map((value, i) => {
      return (
        <Link
          to={value.href}
          key={i}
          data-analytics={`{"event":"cta_interaction",
            "click_text":"${value.title}",
            "destination_url":"${value.href}",
            "click_type":"Internal",
            "module":"Footer menu"
          }`}
        >
          <span>{value.title}</span>
        </Link>
      )
    })
    return navLink;
  }

  const cvLogo = getImage(
    data.prismicBlindcvFooter.data.cv_logo.localFile.childImageSharp
      .gatsbyImageData
  )
  const unicornLogo = getImage(
    data.prismicBlindcvFooter.data.copyright_logo.localFile.childImageSharp
      .gatsbyImageData
  )

  const SocialShare = socialData => {
    let fbImage = getImage(
      socialData.socialData[2].social_logo.localFile.childImageSharp
        .gatsbyImageData
    )
    let twitterImage = getImage(
      socialData.socialData[1].social_logo.localFile.childImageSharp
        .gatsbyImageData
    )
    let linkedinImage = getImage(
      socialData.socialData[0].social_logo.localFile.childImageSharp
        .gatsbyImageData
    )
    return (
      <>
        <a
          href={linkedinUrl}
          target="_blank"
          className="hover-menu"
          rel="noreferrer"
          data-analytics={`{"event":"social_share",
            "click_text":"Linkedin",
            "destination_url":"${linkedinUrl}",
            "click_type":"external",
            "module":"footer"
          }`}
        >
          <GatsbyImage
            image={linkedinImage}
            alt={socialData.socialData[0].social_logo.alt}
          />
          <span className="button turquoise-blue">
            {socialData.socialData[0].social_title}
          </span>
        </a>

        <a
          href={twitterUrl}
          target="_blank"
          className="hover-menu"
          rel="noreferrer"
          data-analytics={`{"event":"social_share",
            "click_text":"Twitter",
            "destination_url":"${twitterUrl}",
            "click_type":"external",
            "module":"footer"
          }`}
        >
          <GatsbyImage
            image={twitterImage}
            alt={socialData.socialData[1].social_logo.alt}
          />
          <span className="button turquoise-blue">
            {socialData.socialData[1].social_title}
          </span>
        </a>

        <a
          href={fbUrl}
          target="_blank"
          className="hover-menu"
          rel="noreferrer"
          data-analytics={`{"event":"social_share",
            "click_text":"Facebook",
            "destination_url":"${fbUrl}",
            "click_type":"external",
            "module":"footer"
          }`}
        >
          <GatsbyImage
            image={fbImage}
            alt={socialData.socialData[2].social_logo.alt}
          />
          <span className="button turquoise-blue">
            {socialData.socialData[2].social_title}
          </span>
        </a>
      </>
    )
  }

  return (
    <div className="container">
      <div className="footer">
        <div className="footer-logo">
          <Link
            to="/"
            data-analytics={`{"event":"global_navigation_interaction",
            "click_text":"Logo - Anon CV",
            "destination_url":"/",
            "click_type":"internal",
            "module":"footer"
          }`}
          >
            <GatsbyImage
              image={cvLogo}
              alt={data.prismicBlindcvFooter.data.cv_logo.alt}
              s="fillwidth"
            />
          </Link>
          <a
            href="https://www.digitas.com"
            target="_blank"
            rel="noreferrer"
            data-analytics={`{"event":"global_navigation_interaction",
            "click_text":"Logo - Digitas",
            "destination_url":"https://www.digitas.com/en-in",
            "click_type":"external",
            "module":"footer"
          }`}
          >
            <img src={logo2} alt="Digitas Logo" className="digitas" />
          </a>
        </div>
        <hr />
        <p className="subtitle">Share the Anon CV</p>
        <div className="share-icons">
          <SocialShare
            socialData={data.prismicBlindcvFooter.data.social_share}
          ></SocialShare>
        </div>
        <p>{data.prismicBlindcvFooter.data.social_description}</p>
        <hr />
        <div className="footer-menu">
          <FooterMenu data={data.prismicBlindcvFooter.data.menu} />
          <button id="ot-sdk-btn" className="ot-sdk-show-settings"> Cookie Settings</button>
        </div>
        <a
          href="https://www.digitas.com"
          target="_blank"
          rel="noreferrer"
          data-analytics={`{"event":"global_navigation_interaction",
          "click_text":"Logo - Digitas Unicorn",
          "destination_url":"https://www.digitas.com/en-in",
          "click_type":"external",
          "module":"footer"
        }`}
        >
          <GatsbyImage
            image={unicornLogo}
            alt={data.prismicBlindcvFooter.data.copyright_logo.alt}
            className="unicorn"
          />
        </a>
        <span>{data.prismicBlindcvFooter.data.copyright_text}</span>
      </div>
    </div>
  )
}
export default SiteFooter
